.overallWrapper{
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.overallRate {
  align-items: center;
  display: flex;
  flex-direction: row;

.progressBarWrapper {
  align-items: center;
}
.progress {
  margin-bottom: 5px !important;
  height: .5rem !important;
}

.rating {
  font-size: 1rem;
  display: flex;
  justify-content: start;
  padding: 0rem 1.5rem;
  align-items: end;
  p {
    margin: 0;
  }
}

}
.overall {
  align-items: start;
}
.reviewTypeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 2rem !important;

  .caption {
    color: #6F6F6F;
    font-weight: 500;
  }

  .value {
    font-weight: 600;
  }
}

.reviewWrapper {
  justify-content: center;
}

.authorWrapper {
  display: flex;
  flex-direction: row;
  align-items: end;

  .caption{
    font-weight: 500;
    margin-right: .5rem;
  }

  .muted {
    font-size: .8rem;
    text-align: start;

  }

}
@media (max-width:425px) {
  .authorWrapper {
    .muted {
      font-size: .6rem;
      text-align: start;
  
    }
  }

  .dateWrapper {
    .mutedDate {
      font-size: 0.6rem;
    }
  }
}


.authorWrapperTrial {
  display: flex;
  flex-direction: column;
  align-items: start;

  .caption {
    align-items: end;
  }
  
}

.authorDateWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding: 0 .5rem 0 0 !important;
  .mutedDate {
    font-size: 0.8rem;
    text-align: start;
    justify-content: end !important;
    margin: 0 !important;
    color: #7C7C7C;
  }
}

@media (max-width:425px) {
  .authorWrapper {
    .muted {
      font-size: .6rem;
      text-align: start;
  
    }
  }

  .authorDateWrapper {
    .mutedDate {
      font-size: 0.6rem;
    }
  }
}
.caption {
  font-size: 0.8rem;
  font-weight: 200;
  margin: 0;
  text-align: start;
}

.value {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
}

.muted {
  font-size: .8rem;
  color: #7C7C7C;
  font-weight: 400;
  margin-bottom: 0;
}

.verticalDividerWrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.dividerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.horizantalDivider {
  width: 20%;
  height: 10px;
  color: #393939;
  align-self: center;
}

.horizantalDividerForBLocks {
  width: 40% !important;
  height: 1px;
  border-radius: 25px;
  background-color: #424242;
  margin: 10px 0px 15px 0px;
}

.authorWrapperV2 {
  align-items: end;
  justify-content: space-between;
  display: flex;

  .caption{
    font-weight: 500;
    margin-right: .5rem;
  }

  .muted {
    font-size: .8rem;
    text-align: end;

  }
  .dateWrapper {
    justify-content: end;
    display: flex;
    .mutedDate {
      font-size: 0.8rem;
      text-align: start;
      justify-content: end !important;
      margin-left: 10px !important;
      color: #7C7C7C;
    }
}



}
