.caption {
  font-size: .8rem;
  font-weight: 200;
  margin: 0;
  text-align: start;
}

.detail {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: start;
}

.iconTextContainer {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.413);
  flex-direction: column;
  width: 150px !important;
  height: 150px !important;
  border-radius: 50%;
  padding: 0px;
}

.field {
  display: block;
  width: 100%;
  padding: .375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.field:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.errorText {
  color: red;
  font-style: italic;
}