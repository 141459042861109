.socialsDispayWrapper {
  padding: 0rem 2rem;

  .socialsDispayContainer {
    background-color: #ffffff;
    justify-content: start;
    align-items: center;
    margin-bottom: 0.5rem !important;
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: .5rem;

    .socialLogoWrapper {
      display: flex;
      align-items: center;
      justify-content: start;
      padding-right: 1rem;

      .socialLogo {
        width: 25px;
        height: 25px;
        object-fit: contain;
        border-radius: 5px;
      }

    }

    .socialsDisplay {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }

    .socialHandle {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.2rem;
      text-align: start;
    }
  }

  .basicAnchor {
    text-decoration: none;
    color: #000000;
  }

  .socialsDispayContainer:hover {
    background-color: #000000;
    color: #ffffff;
    transition: 0.3s;
    cursor: pointer;

    .socialLogoWrapper {

      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      border-radius: 50px;
      padding-right: 1rem;

      .socialLogo {
        width: 25px;
        height: 25px;
        object-fit: contain;

      }

    }
  }
}

.socialsDispayWrapperV2 {
  background-color: #ffffff;
  margin-bottom: .5rem !important;

  .socialLogoWrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-right: 1rem;

    .socialLogo {
      width: 25px;
      height: 25px;
      object-fit: contain;
      border-radius: 5px;
    }

  }

  .detailWrapper {
    // padding-left: 1.5rem;
  }

  @media(max-width:768px) {
    .detailWrapper {
      padding-left: .5rem;
    }
  }

  .socialsDisplay {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .socialHandle {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
    ;
    text-align: start;
  }
}


.field {
  display: block;
  width: 100%;
  padding: .375rem;
  margin: .5rem 0rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: .5rem 0rem;
}


.field:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.errorText {
  color: red;
  font-style: italic;
}

.verifyButton {
  background: rgb(217, 0, 0);
  color: white;
  width: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  padding: 0rem 0.3rem;
  border-radius: 5px;
  justify-content: center;
  border: solid 1px rgb(197, 0, 0);
}

.movableBlock {
  border: .5px solid #D9D9D9;
  border-radius: 5px;
}

.formSub {
  text-align: start;
  font-size: .8rem;
  font-weight: 300;
  color: #000000;
  margin-right: 2px;
}

.followerCount {
  display: flex;
  align-items: baseline;
}

.engRate {
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }
}

.statWrapper {
  display: flex;
  align-items: end;
  justify-content: center;
}

.statContainer {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 50%;

}

@media (max-width:768px) {
  .statContainer {
    flex-direction: column;
    align-items: flex-start;
  }

}

.text {
  font-size: 0.7rem !important;
  color: #7C7C7C;
  font-weight: 400;
  margin: 0;
}