.heroNameBasic {
  display: flex;
  font-size: 2rem;
  color: #000;
  font-weight: 500;
  padding: 0;
  text-align: start;
  margin: 0.5em 0em 0.5em 0em;
  align-items: center;
}

@media (max-width: 768px) {
  .heroNameBasic {
    text-align: center;
  }
}

@media (max-width: 425px) {
  .heroNameBasic {
    flex-direction: column;
  }
}

.field {
  display: flex;
  padding: .375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0rem .5rem;
}

.field:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.errorText {
  color: red;
  display: block;
  font-style: italic;
  font-size: 1rem;
}