.blurredDetail {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: start;
  display: block;
  filter: blur(10px);
}

.detail {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0rem;
  text-align: start;
  display: block;
}