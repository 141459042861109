.brandWrapper {
  margin-bottom: 1rem;

  .brandName {
    h5 {
      font-size: 1rem;
      font-weight: 600;
      text-align: left;
      margin: 0;
    }


  }

  .date {
    font-size: 0.8rem !important;
    color: #7C7C7C;
    font-weight: 400;
    text-align: left;
    margin: 0;
  }

  .cardImage {
    position: relative;
  }

  .brandImage {
    position: absolute;
    top: 0;
    border-radius: 0px 0px 5px 0px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
  }

  .brandImage img {
    width: 35px !important;
    height: 35px !important;
    padding: .5rem;

  }



  .statCounter {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
    padding: 0rem .5rem;

    .iconTextContainer {
      display: flex;
      align-items: end;
      justify-content: start;
      text-align: start;
      // padding: .5rem;

      .icon {
        font-size: 1rem;
        color: #7C7C7C;
      }

    }

  }

  .divider {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7c7c7c76;
    padding: 0 !important;
    max-width: 1px !important;
    margin: .5rem 0rem !important;
    border-radius: 5px;
  }
}


@media (max-width: 425px) {
  .brandWrapper {
    margin-bottom: 1.5rem;
  }
}

.formSub {
  font-size: .8rem;
  font-weight: 300;
  color: #4a4a4a;
  margin: 0;
}


.field {
  display: block;
  width: 100%;
  padding: .375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.field:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}


.postLinkWrapper {
  display: flex;
  flex-direction: column;
  margin-top: .5rem;

  .postLinkSelectWrapper {
    padding: .5rem .5rem .0rem .0rem;
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    justify-content: center;

  }

  .postLinkFieldWrapper {
    padding: .5rem .5rem .0rem .0rem;

  }
}

.infoBox {
  padding: 1rem;
  background: aliceblue;
  border-radius: 10px;
  margin: 1rem;
}

.errorText {
  color: red;
  font-style: italic;
}

.collabBrands {
  padding-top: .5rem;
  display: flex;
  justify-content: end;
}

.viewMoreButton {
  background-color: transparent;
  text-decoration: none;
  color: #0066ff;
  border: 0;
}

.text {
  font-size: 0.6rem !important;
  color: #7C7C7C;
  font-weight: 400;
  margin: 0;
}

.stat {
  padding: 0.3rem 0rem 0rem 0rem;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}

.captionBox {
  font-size: .8rem;
}