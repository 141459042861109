.coverPictureWrapper {
  margin: 0 !important;
  justify-content: center;




  .coverPictureBasic:hover {
    background-color: rgba(255, 255, 255, 0.7);

  }

  .coverPictureBasicAfterEdit {
    width: 100%;
    min-height: 350px;
    object-fit: cover;
    padding: 0px;
    border-radius: 0px 0px 30px 30px;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: start;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    border: 2px dotted black;

    p {
      color: black;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin-top: 20px;
    }
  }

  .uploadingWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .uploadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    height: fit-content;
    padding: 2rem;
    border-radius: 10px;
  }

  .editIconContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .editTextContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .profilePictureBasic {
    width: 100px;
    height: 100px;
    background-size: cover;
    padding: 0px;
    border-radius: 50%;
    display: inline-block;
    margin-top: -80px;
  }

  .profilePictureBasicDesktop {
    width: 150px;
    height: 150px;
    background-size: cover;
    padding: 0px;
    border-radius: 50%;
    display: inline-block;
    background-position: center;
  }

  .iconWrapper {
    padding: 10px;
    width: 48px;
    height: 48px;
    background-color: #1c55a9;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .imagesContainer {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border: 2px solid #A9A9A9;
    border-radius: 15px;
    padding: 1rem;
    min-width: fit-content !important;

    h5 {
      font-weight: 600;
    }

    .addMoreButton {
      background-color: transparent;

      h5 {
        color: #57BAFF;
        font-weight: 400;
      }
    }

    .dropOverlay {
      display: flex !important;
      width: 100%;
      height: 100px;
      object-fit: cover;
      padding: 0px;
      border-radius: 5px;
      display: flex;
      background-color: #000000;
      opacity: 0.5;
      color: white;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 600;


    }
  }
}

.popoverBody {
  width: 600px;
  height: 300px;
  overflow-y: scroll;
  overflow-x: clip;
}

.buttonContainer {
  width: 100px !important;
  height: 100px !important;
  object-fit: cover;
  padding: 5px !important;
  margin: 1rem !important;
  border-radius: 5px;
  display: flex !important;
  align-items: flex-end;
  justify-content: space-between;
  border: 1px solid #a3a3a3;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
}

.avatarWrapper {
  display: flex;
  justify-content: center;
}

.iconContainer {
  padding: 1rem;
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 20px;
  z-index: 9999;
}

.coverPictureBasic {
  width: 100%;
  min-height: 350px;

  object-fit: contain;
  padding: 0px 0px 20px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: stretch;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;

  p {
    color: black;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }
}