.profilePictureBasicMobileWrapper {
  min-width: 100px !important;
  height: 100px !important;
  background-size: cover;
  padding: 0px;
  border-radius: 50%;
  margin-top: -60px !important;
  background-position: center;
}

.profilePictureBasicMobile {
  width: 100px !important;
  height: 100px !important;
  background-size: cover;
  padding: 0px;
  display: inline-block;
  border-radius: 50%;
}

.profilePictureBasicDesktop {
  width: 150px;
  height: 150px;
  background-size: cover;
  padding: 0px;
  border-radius: 50%;
  display: inline-block;
}

.profileUploadingText {
  font-size: .8rem;
  font-weight: 300;
  text-align: center;
  margin: 0;

}

.uploadingWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px !important;
  height: 150px !important;
  border-radius: 50%;
  background-size: cover;
  padding: 0px;
  border: 2px dotted black;
  background-position: center;
}

.iconTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.413);
  flex-direction: column;
  width: 150px !important;
  height: 150px !important;
  border-radius: 50%;
  padding: 0px;
}