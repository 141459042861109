.rateBlockWrapper {
  padding: 1rem;
}

.rateBlockContainer {
  padding: .5rem 1rem;


  .subtitle {
    font-size: .8rem;
    font-weight: 400;
    margin-bottom: 0rem !important;
    text-align: start;
  }

  .handleLink {
    margin-right: 10px;
    text-decoration: none;
  }

}

.priceRangeWrapper {
  display: flex !important;
  align-items: center !important;
  padding: 0rem 1rem;

}

.priceRange {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0rem;
  text-align: start;
  display: flex;
  align-items: center;


  svg {
    margin-left: 10px;
  }

}

.caption {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0;
  text-align: start;
}

.field {
  display: block;
  width: 100%;
  padding: .375rem;
  margin: .5rem 0rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.field:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

@media (max-width:425px) {
  .priceRange {
    font-size: 1rem;
  }

  .rateBlockContainer {
    padding: 1rem 0rem 1rem 1rem;
  }



  .priceRangeWrapper {
    padding: 0;
  }
}

.errorText {
  color: red;
  font-style: italic;
}

.movableBlock {
  border: .5px solid #D9D9D9;
  border-radius: 5px;
}

.formSub {
  font-size: .8rem;
  font-weight: 300;
  color: #000000;
  margin: 0rem .2rem 0rem 0rem;
}

.rateTooltip {
  padding: 1rem;
}

.engRate {
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }
}

.text {
  font-size: 0.4rem !important;
  color: #7C7C7C;
  font-weight: 400;
  margin: 0;
}